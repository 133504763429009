
import { defineComponent, ref } from "vue";
import Dialog from "@/components/ElDialog.vue";

export default defineComponent({
  components: {
    Dialog,
  },
  setup() {

    const isImageVisible = ref(false);
    const showImage = () => {
      isImageVisible.value = true;
    };
    const hideImage = () => {
      isImageVisible.value = false;
    };

    const topList = [
      {
        img: "t1.png",
        name: "在线使用体验",
      },
      {
        img: "t2.png",
        name: "多场景产品支持",
      },
      {
        img: "t3.png",
        name: "1V1客户服务",
      },
      {
        img: "t4.png",
        name: "7X24小时服务",
      },
    ];
    // ../../../public/img/home/quanwei/
    const linkList = [
      {
        title: "产品方案",
        links: [
          {
            // value: "/product/digital_marriage",
            value: "",
            label: "数字办公",
          },
          {
            id: 1,
            value: "",
            label: "数字业财税",
          },
          {
            id: 2,
            value: "",
            label: "数字支付",
          },
          {
            id: 3,
            value: "",
            label: "数字云服务",
          },
          {
            value: "",
            label: "数字营销",
          },
          {
            value: "",
            label: "更多...",
          },
        ],
      },
      {
        title: "解决方案",
        links: [
          {
            // value: "/solution/consumer_finance",
            value: "",
            label: "智慧婚嫁解决方案",
          },
          {
            id: 1,
            value: "",
            label: "智慧教育解决方案",
          },
          {
            id: 2,
            value: "",
            label: "智慧健康解决方案",
          },
          {
            value: "",
            label: "更多...",
          },
        ],
      },
      // {
      //   title: '成功案例',
      //   links: []
      // },
    ];
    const dialogVisible = ref(false);
    // 关闭弹窗
    const hidden = (val: boolean) => {
      dialogVisible.value = val;
    };

    const toTop = () => {
      window.scrollTo(0, 0);
    };

    const goWayToLink = () => {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfc2f57dba70b949766";
    }

    return {
      topList,
      linkList,
      dialogVisible,
      hidden,
      toTop,
      goWayToLink,
      isImageVisible,
      showImage,
      hideImage,
    };
  },
});
